<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="modal"
    persistent
    width="300px"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="open"
        color="blue-grey white--text"
      >
        Guia ST
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 blue-grey blue-grey--text text--lighten-5">
        Digite o Valor:
      </v-card-title>

      <v-card-text class="pt-5">
        <v-text-field-money
          filled
          autofocus
          label="Valor"
          v-model="price_st"
          ref="field"
          v-bind:properties="{ ...properties }"
          v-bind:options="options"
        ></v-text-field-money>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn elevation="1" @click="close"> Cancelar</v-btn>
        <v-btn
          elevation="2"
          color="blue-grey blue-grey--text text--lighten-5"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalGuiaST",
  props: ["price"],
  data() {
    return {
      price_st: "",
      modal: false,
      resolve: null,
      properties: {
        filled: true,
        prefix: "R$",
        placeholder: " ",
      },
      options: {
        locale: "pt-BR",
        length: 11,
        precision: 2,
        empty: null,
      },
    };
  },
  methods: {
    open() {
      this.price_st = this.price;
      this.modal = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.clear();
      this.modal = false;
    },
    save() {
      this.$emit("onsave", this.price_st);
      this.resolve && this.resolve();
      this.close();
    },
    clear() {
      this.price_st = "";
      this.$refs.field.focus();
    },
  },
};
</script>

<style scoped></style>
