<template>
  <v-dialog v-model="modal" persistent width="290px">
    <v-card>
      <v-card-title class="text-h5 warning lighten-2"> Atenção </v-card-title>

      <v-card-text class="pt-5">
        <v-text-field v-if="+idCoin === 14"
          :loading="loading"
          autofocus
          ref="code"
          v-model.lazy="code"
          label="Informe o código"
          hint="ddmmaaaahhmm"
          maxLength="12"
          @keypress="onlyNumbers"
          @keypress.enter="save"></v-text-field>

        <v-text-field v-else
          :loading="loading"
          autofocus
          ref="code"
          v-model.lazy="code"
          label="Informe o código"
          @keypress.enter="save"></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn elevation="1" @click="close">Cancelar </v-btn>
        <v-btn elevation="1" color="primary" @click="save" :disabled="!isValid"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinSnack from "../../_mixins/snackbar";

export default {
  name: "ModalCode",
  mixins: [mixinSnack],
  data() {
    return {
      modal: false,
      code: "",
      resolve: null,
      loading: false,
      idCoin: null,
    };
  },
  methods: {
    open(idCoin = null) {
      if (idCoin) this.idCoin = idCoin
      this.modal = true;
      this.clear();
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.modal = false;
      this.resolve();
      this.clear();
    },
    onlyNumbers(event) {
      if (!/[\d]/.test(event.key))
        event.preventDefault();
    },
    async check(code = null) {
      code = code ? code : this.code;
      let { status } = await this.$axios(`/check-code/${code}`);
      return +status === 202 ? false : true;
    },
    async save() {
      if (this.code.trim().length < 3) {
        this.snackWarning("O campo código é obrigatório!");
        this.$refs.code.focus();
        return;
      }
      this.loading = true;
      const { status } = await this.$axios(`/check-code/${this.code}`);
      this.loading = false;
      if (status === 203) {
        this.snackError("Código já informado!");
        this.$refs.code.focus();
        return;
      } else if (status === 202) {
        this.$emit("onsave", this.code);
        this.close();
        return;
      } else {
        this.snackError("Erro ao verificar o código!");
      }
      this.$refs.code.focus();
    },
    clear() {
      this.code = "";
    },
  },
  computed: {
    isValid() {
      if (+this.idCoin === 14) {
        return /[\d]{12}/.test(this.code) || false
      }
      return true
    },
  }
};
</script>

<style scoped></style>
