<template>
  <div class="d-flex flex-fill fill-height justify-center align-center">
    <v-card class="payment">
      <v-card-title class="amber">
        <h4>Pagamento</h4>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :items="carriers"
                    clearable
                    ref="carrying"
                    item-text="name"
                    item-value="id"
                    v-model="carryingId"
                    label="Transportadora"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="`Saldo Em Cateira de: ${clientName}`"
                    :value="creditInPortfolio || 0 | money"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field-money
                    label="Taxa Administrativa"
                    v-model="tax"
                    v-bind:properties="properties"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field-money
                    label="Frete"
                    v-model="freight"
                    v-bind:properties="properties"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field-money
                    filled
                    autofocus
                    label="Guia ST"
                    v-model="price_st"
                    ref="field"
                    v-bind:properties="properties"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field-money
                    label="Desconto"
                    v-model="discount"
                    v-bind:properties="properties"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="4">
                  <v-text-field-money
                    label="Valor á Pagar"
                    :value="total"
                    v-bind:properties="{ ...properties, disabled: true }"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="4">
                  <v-text-field-money
                    label="Valor Pago"
                    :value="totalPaid"
                    v-bind:properties="{ ...properties, disabled: true }"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="4">
                  <v-text-field-money
                    label="Troco"
                    v-model="balance"
                    v-bind:properties="{ ...properties }"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col hidden>
                  <ModalScheduled
                    ref="modalScheduled"
                    @onsave="saveDate"
                  ></ModalScheduled>
                </v-col>
                <v-col hidden>
                  <ModalCode ref="modalCode" @onsave="saveCode"></ModalCode>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                    filled
                    :items="coins"
                    :filter="customFilter"
                    clearable
                    ref="coin"
                    item-text="name"
                    item-value="id"
                    v-model="coinId"
                    label="Moeda"
                    @change="setCoin"
                    @click:clear="clearCoin"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field-money
                    label="Valor"
                    v-model="price"
                    ref="price"
                    v-bind:properties="{ ...properties, ref: 'price' }"
                    v-bind:options="options"
                  ></v-text-field-money>
                </v-col>
                <v-col cols="2" sm="1" class="pa-0 pt-2">
                  <v-tooltip bottom transition="scroll-y-transition">
                    <template v-slot:activator="{ on, attr }">
                      <v-btn
                        icon
                        elevation="2"
                        color="blue"
                        class="mt-3"
                        title="Adicionar Moeda"
                        v-on="on"
                        v-bind="attr"
                        @click="addCoin"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar Moeda</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-simple-table dense class="elevation-3">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Moeda</th>
                          <th class="text-left">Valor</th>
                          <th class="text-left">Agendamento</th>
                          <th class="text-left">Código</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in selectedCoins" :key="i">
                          <td>{{ item.coin.name }}</td>
                          <td>{{ item.price }}</td>
                          <td>
                            <v-btn
                              color="blue"
                              text
                              @click.prevent="changeDate(i)"
                            >
                              {{ item.date | dateBR }}
                            </v-btn>
                          </td>
                          <td>{{ item.code }}</td>
                          <td>
                            <v-btn icon color="red" @click="removeCoin(i)">
                              <v-icon>mdi-delete-empty</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="!selectedCoins.length">
                        <tr>
                          <td colspan="5" class="text-center">
                            Nenhum produto até o momento!
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-between">
                  <ModalObservation
                    :text="observation"
                    @onsave="saveObservation"
                  ></ModalObservation>
                  <ModalGuiaST v-if="false"
                    :price="price_st"
                    @onsave="(v) => (price_st = v)"
                  ></ModalGuiaST>
                  <!-- <ModalConfirmBalance ref="modalConfirmBalance" :balance="balance" /> -->
                  <v-btn
                    :disabled="loading || !isPositive"
                    color="blue white--text"
                    @click="send"
                  >
                    Finalizar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import mixinSnack from "../_mixins/snackbar";
import ModalCode from "./payment/ModalCode";
import ModalScheduled from "./payment/ModalScheduled";
import ModalObservation from "./payment/ModalObservation";
import ModalGuiaST from "./payment/ModalGuiaST";

export default {
  name: "Payment",
  components: { ModalGuiaST, ModalObservation, ModalScheduled, ModalCode },
  mixins: [mixinSnack],
  data() {
    return {
      loading: false,
      indexCoin: null,
      coinId: 0,
      code: "",
      price: null,
      price_st: 0,
      date: null,
      carryingId: null,
      selectedCoins: [],
      freight: 0,
      tax: 0,
      discount: 0,
      balance: 0,
      observation: null,
      properties: {
        filled: true,
        prefix: "R$",
        placeholder: " ",
      },
      options: {
        locale: "pt-BR",
        length: 11,
        precision: 2,
        empty: null,
      },
    };
  },
  beforeMount() {
    if (!this.total) this.$router.push("/pdv");
  },
  mounted() {},
  methods: {
    ...mapActions(["clear"]),
    ...mapActions({ loadProducts: "product/loadProducts" }),
    addCoin() {
      if (!this.coinId || this.price === null || !this.price.length) {
        return;
      }

      const [coin] = this.coins.filter((i) => i.id === this.coinId);
      if (
        +coin.is_code &&
        this.code.trim() &&
        !this.$refs.modalCode.check(this.code)
      ) {
        this.snackWarning({ message: "Código já informado!", timeout: 1000 });
        this.$refs.modalCode.open();
        return;
      }

      if (+coin.is_code && !this.code.trim().length) {
        this.snackError("Código não informado!");
        this.$refs.modalCode.open();
        return;
      }

      if (+this.coinId === 3) {
        if (+this.price > +this.client.creditInPortfolio) {
          this.snackWarning("Saldo insuficiente!!");
          return;
        } else {
          this.$store.commit("debitClient", +this.price);
        }
      }

      const { price, date, code } = this;
      this.selectedCoins.push({ coin, price, date, code });
      this.clearCoin();
    },
    removeCoin(i) {
      const moeda = this.selectedCoins[i];
      if (+moeda.coin.id === 3) {
        this.$store.commit("creditClient", +moeda.price);
      }
      this.selectedCoins.splice(i, 1);
    },
    async setCoin() {
      if (this.coinId) {
        await this.checkSchedule();
        await this.checkValidCode();
        this.$refs.price.focus();
      }
    },
    changeDate(index = null) {
      this.$refs.modalScheduled.open();
      this.indexCoin = index;
    },
    saveDate(value) {
      const { indexCoin } = this;
      if (indexCoin != null) {
        this.selectedCoins[indexCoin].date = value;
        this.indexCoin = null;
        return;
      }
      this.date = value;
    },
    saveCode(value) {
      this.code = value;
    },
    saveObservation(value) {
      this.observation = value;
    },
    async send() {
      if (!+this.carryingId) {
        this.snackWarning("Selecione uma transportadora!");
        return;
      }

      // Wait to confirm Modal
      // if (this.balance > 0 && !await this.$refs.modalConfirmBalance.open()) return;
      
      this.loading = true;

      try {
        // prettier-ignore
        const { products, selectedCoins, freight, tax, discount, carryingId, client, seller, total,
          price_st, walletId, observation, balance } = this;
        const request_id = this.request?.id || null;
        // prettier-ignore
        const sale = { products, selectedCoins, freight, tax, discount, carryingId, client, seller, total,
          price_st, request_id, walletId, balance, observation };
        // prettier-ignore
        const { data: { success: { sale: { id }}}} = await this.$axios.post("/sale", sale);
        this.snackSuccess("Pagamento efetuado com sucesso!");
        this.clear();
        this.loadProducts();
        await this.$router.push(`/app/reports/salePayment/${id}`);
      } catch (errors) {
        const {
          response: {
            data: { error },
          },
        } = errors;
        this.snackError(error || "Erro ao finalizar a venda!");
      }
      this.loading = false;
    },
    async checkValidCode() {
      const coin = this.coins.find((c) => +c.id === parseInt(this.coinId));
      if (coin && +coin.is_code) {
        await this.$refs.modalCode.open(coin.id);
      }
    },
    async checkSchedule() {
      const search = (o) => +o.id === parseInt(this.coinId);

      if (this.date) return true;

      if (+this.coins.find(search)?.is_scheduled && !this.date) {
        await this.$refs.modalScheduled.open();
      }
    },
    customFilter(coin, text) {
      const textOne = coin.name.toString().toLowerCase();
      const textTwo = coin.id.toString();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1 || textTwo === searchText;
    },
    clearCoin() {
      this.$refs.coin.focus();
      this.coinId = 0;
      this.price = null;
      this.date = null;
      this.code = "";
    },
  },
  computed: {
    ...mapGetters({
      totalProducts: "total",
      listCoins: "payment/coins",
      carriers: "carrier/carriers",
    }),
    ...mapGetters(["request", "products", "client", "seller"]),
    coins() {
      return this.listCoins.filter(({ wallet_id }) => +wallet_id > 0);
    },
    total() {
      const { freight, tax, discount, price_st, totalProducts } = this;
      return (
        (parseFloat(totalProducts) || 0) +
        (parseFloat(freight) || 0) +
        (parseFloat(tax) || 0) +
        parseFloat(price_st) -
        (parseFloat(discount) || 0)
      );
    },
    totalPaid() {
      const { selectedCoins } = this;
      if (selectedCoins.length) {
        const totals = selectedCoins.map((o) => parseFloat(o.price));
        return totals.reduce((a, c) => a + c);
      }
      return 0;
    },
    // balance() {
    //   return this.totalPaid - this.total || 0;
    // },
    clientName() {
      return this.client ? this.client.name.trim().split(" ")[0] : "";
    },
    creditInPortfolio() {
      if (this.client && typeof this.client == "object") {
        return Object.keys(this.client).includes("creditInPortfolio")
          ? this.client.creditInPortfolio
          : 0;
      }
      return 0;
    },
    isPositive() {
      let sum = (a, c) => c + a
      let total = [+this.total, +this.balance].reduce(sum, 0)
      return parseFloat(total.toFixed(2)) === parseFloat(this.totalPaid.toFixed(2))
      // return parseFloat(this.totalPaid.toFixed(2)) - parseFloat(this.total.toFixed(2)) >= 0
    },
  },
  filters: {
    dateBR(value) {
      // prettier-ignore
      return value ? value.split("-").reverse().join("/") : null;
    },
  },
};
</script>

<style scoped>
.payment {
  max-width: 720px;
}
</style>
