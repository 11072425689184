<template>
  <v-dialog v-model="modal" persistent width="290px">
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <div class="d-flex justify-space-between">
        <v-btn v-if="false" text color="primary" @click="close"> Cancelar </v-btn>
        <v-btn text color="primary" @click="save"> Salvar </v-btn>
      </div>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import mixinSnack from "../../_mixins/snackbar";

export default {
  name: "ModalScheduled",
  mixins: [mixinSnack],
  data() {
    return {
      date: null,
      modal: false,
      resolve: null,
    };
  },
  methods: {
    open() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.modal = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.modal = false;
      this.resolve();
      this.clear();
    },
    save() {
      if (this.date) {
        this.$emit("onsave", this.date);
        this.close();
        return;
      }
      this.snackInfo("Nenhuma data foi informada!");
    },
    clear() {
      this.date = null;
    },
  },
};
</script>

<style scoped></style>
