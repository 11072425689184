<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="modal"
    persistent
    width="300px"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" @click="open" color="teal white--text">
        Observação
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 teal teal--text text--lighten-5">
        Observação
      </v-card-title>

      <v-card-text class="pt-5">
        <v-textarea
          filled
          autofocus
          name="input-7-4"
          ref="field"
          label="Informe uma Observação!"
          v-model="observation"
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn elevation="1" @click="close"> Cancelar </v-btn>
        <v-btn
          elevation="2"
          color="teal teal--text text--lighten-5"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalObservation",
  props: ["text"],
  data() {
    return {
      observation: "",
      modal: false,
      resolve: null,
    };
  },
  methods: {
    open() {
      this.observation = this.text;
      this.modal = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.clear();
      this.modal = false;
    },
    save() {
      this.$emit("onsave", this.observation);
      this.resolve && this.resolve();
      this.close();
    },
    clear() {
      this.observation = "";
      this.$refs.field.focus();
    },
  },
};
</script>

<style scoped></style>
